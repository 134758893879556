import React from 'react'
import { useState  } from "react";

import Layout from './Layout'
import propgal from '../assets/images/prop-gal.png'
import propgal2 from '../assets/images/prop-gal2.png'
import propgal3 from '../assets/images/prop-gal3.png'
import propgal4 from '../assets/images/prop-gal4.png'
import propgal1 from '../assets/images/prop-gal1.png'
import print from '../assets/images/print.png'
import hide from '../assets/images/hide_image (1).png'
import hallway from '../assets/images/hallway.png'
import compare from '../assets/images/compare.png'
import share from '../assets/images/share.png'
import fav from '../assets/images/Component 1 (3).png'
import { Link, useNavigate } from 'react-router-dom'
import { useEffect, } from "react";
import ShareModal from './modals/shareModal'
import propimg from '../assets/images/propimg.png'
import architecture from '../assets/images/architecture.png'
import settings_backup_restore from '../assets/images/settings_backup_restore.png'
import Form from 'react-bootstrap/Form';
import { CarouselProvider, Slider, Slide, ButtonBack, ButtonNext } from "pure-react-carousel";
import "pure-react-carousel/dist/react-carousel.es.css";
import imghouse1 from "../assets/images/img1.png";
import exclusive from "../assets/images/exclusive.svg";
import youtubr from "../assets/images/youtubr.svg";
import rightarrow from "../assets/images/right-arrow.svg";
import leftarrow from "../assets/images/left-arrow.svg";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { detailsPropertyAction } from "../redux/actions/PropertyAction"; // Import your action
import Spinner from '../commonComponent/spinner'
import ImageModal from './modals/imageModal';
import addToCart, { getRelatedProperties } from "../service/apiService"; // Import the addToCart function
import { ToastContainer, toast } from "react-toastify";
import { foundation_Select_data, garage_type_Select_data, plan_style_select_data } from '../constant/constant';

const PropertyDetail = () => {
    const { propertyDetails, loading, error } = useSelector(state => state.property);
    const [showShareModal, setShowShareModal] = useState(false);
    const { id } = useParams(); // Get propertyId from URL
    const dispatch = useDispatch();
    const [showImageModal, setShowImageModal] = useState(false);
    const [imageId, setImageId] = useState(null);
    const navigate = useNavigate()

    const [relatedProperties, setRelatedProperties] = useState([]);
    const [loadingrelate, setLoadingrelate] = useState(false);
    const [errorrelate, setErrorrelate] = useState(null);
    const planDetails = propertyDetails?.plan_details || [];
    const included = propertyDetails?.included || [];
    const notIncluded = propertyDetails?.not_included || [];

    const [formData, setFormData] = useState({
        property_id: '',
        plan_set: '',
        foundation: '',
        options: '',
    });

    const [isDisabled, setIsDisabled] = useState(false);

    const handleClick = () => {
      if (!isDisabled) {
        // Trigger the mailto link
        window.location.href = `mailto:rholzsquad.studio@gmail.com?subject=Foundation%20Modification%20Request&body=Hello,%0AI%20am%20interested%20in%20a%20different%20foundation%20type%20for%20Plan%20Number:%20${propertyDetails?.plan_number}%20-%20Property%20Name:%20${propertyDetails?.name}.%20Please%20provide%20a%20quote%20or%20further%20details.%0AThanks!`;
        
        // Disable the button and reset after 1.5 seconds
        setIsDisabled(true);
        setTimeout(() => {
          setIsDisabled(false);
        }, 2000); // 1500ms = 1.5 seconds
      }
    };
    const handleClickforall = () => {
        if (!isDisabled) {
          // Trigger the mailto link
          window.location.href = `mailto:rholzsquad.studio@gmail.com?subject=Wall%20Modification%20Request&body=Hello,%0AI%20am%20interested%20in%20a%20different%20wall%20type%20for%20Plan%20Number:%20${propertyDetails?.plan_number}%20-%20Property%20Name:%20${propertyDetails?.name}.%20Please%20provide%20a%20quote%20or%20further%20details.%0AThanks!`;          
          // Disable the button and reset after 1.5 seconds
          setIsDisabled(true);
          setTimeout(() => {
            setIsDisabled(false);
          }, 2000); // 1500ms = 1.5 seconds
        }
      };

    useEffect(() => {
        if (id) {
            dispatch(detailsPropertyAction({ id: id })); // Dispatch action to get property details by ID
        }

    }, [dispatch, id]);



    useEffect(() => {
        const fetchProperties = async () => {
            try {
                setLoadingrelate(true);
                const data = await getRelatedProperties(propertyDetails?.plan_style_id?._id, propertyDetails._id, 1, 1000);
                setRelatedProperties(data?.data || []); // Adjust path based on API structure
console.log(relatedProperties,'//////////////////' )
          
            } catch (err) {
                setErrorrelate(err.message);
            } finally {
                setLoadingrelate(false);
            }
        };
        fetchProperties();
    }, [propertyDetails?.plan_style_id, propertyDetails?._id]);


    
       // Update `property_id` when `propertyDetails` is available

   useEffect(() => {
    if (propertyDetails?._id) {
        setFormData(prevData => ({
            ...prevData,
            property_id: propertyDetails._id
        }));
    }
}, [propertyDetails]);

    if (loading) { return <Spinner />; }  
    if (error) return <div>Error: {error}</div>;

    const imageSrc = propertyDetails?.main_images?.[0] || propgal;

    const images = [
        ...(propertyDetails?.main_images || []),  // Spread main images
        ...(propertyDetails?.floor_images || []), // Spread floor images
    ];


    const handleShareClick = () => {
      setShowShareModal(true);
    };
  
    const handleCloseModal = () => {
      setShowShareModal(false);
    };
  

    const openImageModal = (id) => {
        console.log('cliked  image id',id)
        setShowImageModal(true);
        setImageId(id);

    };

    const closeImageModal = () => {
        setShowImageModal(false);
        setImageId(null); // Reset the imageId

    };

    const currentUrl = window.location.href;
    










    // --------------------------------------------------cart adding code------------------------------------------------------ 

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prev) => ({ ...prev, [name]: value }));
    };

    const handleSubmit = async () => {
        try {
            const requestData = new URLSearchParams(formData).toString();
            const response = await addToCart(requestData);
            console.log('------------------->',response);
            if (response && response.status === 200) {
                // toast.success("Property successfully added to cart!");
                
                navigate("/cart");
                if(response.data.status === 401){
toast.warning("please login to perform this action")                }
            } else {
                toast.error(
                    response?.data?.message || "Failed to add property to cart."
                );            }
        } catch (error) {
            toast.error("Something went wrong! Please try again.");
        }
    };

    
    // --------------------------------------------------cart adding code------------------------------------------------------ 

    return (
        <>
            <Layout>
            <ImageModal showModal={showImageModal} onClose={closeImageModal} images={images} imageId={imageId} />


            {propertyDetails ? (

                <section className='productinternal'>
                    <div className='container'>
                        <div className='row prop-imggal'>
                            <div className='col-lg-6'>
                                <div className='wrap-img-gal images-main'>
                                    <img src={imageSrc}  onClick={() => openImageModal(imageSrc?._id)} />
                                </div>
                            </div>
                            <div className='col-lg-6 more-imgs'>
                                <div className='row'>
                                {propertyDetails?.floor_images?.length > 0 ? (
                                    propertyDetails?.floor_images.map((image, index) => (
                                    <div className='col-lg-6' key={index}>
                                        <div className='wrap-img-gal'>
                                            <img src={image} onClick={() => openImageModal(image?._id)} />
                                            
                                        </div>
                                    </div>
                                ))
                            ) : (
                                <div className='col-lg-6'>
                                        <div className='wrap-img-gal img-gal'>
                                            <img src={propgal1} />
                                            <div className='viewall'>
                                                <button type="button" class="btn btn-outline-primary"><svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M3.95801 4.79232C3.95801 4.33208 4.3311 3.95898 4.79134 3.95898H7.70801C8.16825 3.95898 8.54134 4.33208 8.54134 4.79232V7.70898C8.54134 8.16922 8.16825 8.54232 7.70801 8.54232H4.79134C4.3311 8.54232 3.95801 8.16922 3.95801 7.70898V4.79232Z" stroke="#F46530" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                    <path d="M3.95801 12.2923C3.95801 11.8321 4.3311 11.459 4.79134 11.459H7.70801C8.16825 11.459 8.54134 11.8321 8.54134 12.2923V15.209C8.54134 15.6692 8.16825 16.0423 7.70801 16.0423H4.79134C4.3311 16.0423 3.95801 15.6692 3.95801 15.209V12.2923Z" stroke="#F46530" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                    <path d="M11.458 4.79232C11.458 4.33208 11.8311 3.95898 12.2913 3.95898H15.208C15.6682 3.95898 16.0413 4.33208 16.0413 4.79232V7.70898C16.0413 8.16922 15.6682 8.54232 15.208 8.54232H12.2913C11.8311 8.54232 11.458 8.16922 11.458 7.70898V4.79232Z" stroke="#F46530" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                    <path d="M11.458 12.2923C11.458 11.8321 11.8311 11.459 12.2913 11.459H15.208C15.6682 11.459 16.0413 11.8321 16.0413 12.2923V15.209C16.0413 15.6692 15.6682 16.0423 15.208 16.0423H12.2913C11.8311 16.0423 11.458 15.6692 11.458 15.209V12.2923Z" stroke="#F46530" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                </svg>
                                                    Show all photos</button>
                                            </div>
                                        </div>

                                    </div>
                                )}
                                    {/* <div className='col-lg-6'>
                                        <div className='wrap-img-gal'>
                                            <img src={propgal4} />
                                        </div>
                                    </div> */}
                                 
                                </div>
                            </div>
                        </div>
                        <div className='prop-details'>
                            <div className='plan'>
                                <span>{propertyDetails?.plan_number}</span>
                            </div>
                            <h2>{ propertyDetails?.name ||'Perfectly Balanced 4-Bed Modern Farmhouse Plan'}</h2>
                            <div className='option-details'>
                                <div className='detial'>
                                    <div className='print'>
                                        <img src={print} />
                                        <span>Print</span>
                                    </div>
                                    <div className='print'>
                                        <img src={hide} />
                                        <span>Hide</span>
                                    </div>
                                    <div className='print'>
                                        <img src={hallway} />
                                        <span>Compare</span>
                                    </div>
                                    <div className='print'>
                                        <img src={compare} />
                                        <span>Flyer</span>
                                    </div>
                                </div>
                                <div className='share'>
                                    <div className='print'>
                                        <img src={share} onClick={handleShareClick}  />
                                        <span>Share</span>
                                    </div>
                                    <ShareModal show={showShareModal} handleClose={handleCloseModal} url={currentUrl} />

                                    {/* <div className='print'>
                                        <img src={fav} />
                                        <span>Share</span>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-lg-8'>
                                <div className='about-det'>
                                    <div className='sqft'>
                                        <strong>{propertyDetails?.sq_ft}</strong>
                                        <p>Sq. Ft.</p>
                                    </div>
                                    <hr></hr>
                                    <div className='sqft'>
                                        <strong>{ propertyDetails?.beds ||'3'}</strong>
                                        <p>Bed</p>
                                    </div>
                                    <hr></hr>
                                    <div className='sqft'>
                                        <strong>{ propertyDetails?.baths||'3'}</strong>
                                        <p>Bath</p>
                                    </div>
                                    <hr></hr>
                                    <div className='sqft'>
                                        <strong>{propertyDetails?.story ||'1'}</strong>
                                        <p>Storey</p>
                                    </div>
                                    <hr></hr>
                                    <div className='sqft'>
                                        <strong>{ propertyDetails?.cars || '2'}</strong>
                                        <p>Cars</p>
                                    </div>
                                </div>
                                <hr className='w-100'></hr>
                                <div className='build-gallry'>
                                    <div className='galry-co'>
                                        <div>
                                            <h5>Build Gallery</h5>
                                            <p>See and hear from existing customers with real photos, videos, and reviews.</p>
                                        </div>
                                        <Link to=""><span>View All</span></Link>
                                    </div>
                                    <div className='row'>
                                        <div className='col-lg-6'>
                                            <div className='propwrap'>
                                                <img src={propimg} />
                                                <div className='p-4'>
                                                    <p>House Plan 56478SM Comes to Life in Tennessee</p>
                                                    <span>13 Client Albums</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-lg-6'>
                                            <div className='propwrap'>
                                                <img src={propimg} />
                                                <div className='p-4'>
                                                    <p>House Plan 56478SM Comes to Life in Tennessee</p>
                                                    <span>13 Client Albums</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <hr className='w-100 mt-5'></hr>
                                    <div className='floor-plan'>
                                        <div className='flor-heading'>
                                            <h4>Floor Plan</h4>
                                            <div className='print'>
                                                <img src={print} />
                                                <span>Print</span>
                                            </div>
                                        </div>
                                        <img src={architecture} />
                                        <div className='mainlevle'>
                                            <h4>Main Level</h4>
                                            <div className='rev'>
                                                <img src={settings_backup_restore} />
                                                <span>Reverse</span>
                                            </div>
                                        </div>
                                        <img src={architecture} />
                                        <div className='mainlevle'>
                                            <h4>Basement Version w/ Room Above Garage - Stair Location</h4>
                                            <div className='rev'>
                                                <img src={settings_backup_restore} />
                                                <span>Reverse</span>
                                            </div>
                                        </div>
                                    </div>
                                    <hr className='w-100 my-5'></hr>
                                    <div>
      {/* Plan Details Section */}
      <div className="plan-details">
        <h4>Plan Details</h4>
        <ul>
          {planDetails.length > 0 ? (
            planDetails.map((detail, index) => (
              <li key={index}>{detail}</li>
            ))
          ) : (
            <li>No plan details available.</li>
          )}
        </ul>
      </div>

      {/* Separator */}
      <hr className="w-100 my-5" />

      {/* Included and Not Included Sections */}
      <div className="row">
        {/* What's Included */}
        <div className="col-lg-6">
          <div className="paln-wrap">
            <h4>What's Included</h4>
            <ul>
              {included.length > 0 ? (
                included.map((item, index) => (
                  <li key={index}>{item}</li>
                ))
              ) : (
                <li>No included items available.</li>
              )}
            </ul>
          </div>
        </div>

        {/* What's Not Included */}
        <div className="col-lg-6">
          <div className="paln-wrap">
            <h4>What's Not Included</h4>
            <ul>
              {notIncluded.length > 0 ? (
                notIncluded.map((item, index) => (
                  <li key={index}>{item}</li>
                ))
              ) : (
                <li>No not included items available.</li>
              )}
            </ul>
          </div>
        </div>
      </div>
    </div>
                                    <hr className='w-100 my-5'></hr>
                                </div>
                            </div>
                            <div className='col-lg-4'>
                                <div className='start-plan'>
                                    <div className='d-flex justify-content-between'>
                                        <p>Starting at </p>
                                        <div className='price'>
                                            {/* <span><strike>$1,295</strike></span> */}
                                            <strong>${propertyDetails?.price}</strong>
                                        </div>
                                    </div>
                                    {/* <div className='mb-3'>
                                        <Form.Label>Plan Set</Form.Label>
                                        <Form.Select
                    name="plan_set"
                    value={formData.plan_set}
                    onChange={handleChange}
                >                                            <option>Open this select menu</option>
                                            <option value="1">One</option>
                                            <option value="2">Two</option>
                                            <option value="3">Three</option>
                                        </Form.Select>
                                    </div> */}
                                    {/* <div className='mb-3'>
                                        <Form.Label>Foundation</Form.Label>
                                        <Form.Select
                    name="foundation"
                    value={formData.foundation}
                    onChange={handleChange}
                >                                            <option>Open this select menu</option>
                                            <option value="1">One</option>
                                            <option value="2">Two</option>
                                            <option value="3">Three</option>
                                        </Form.Select>
                                    </div> */}
                                    {/* <div className='mb-3'>
                                        <Form.Label>Options</Form.Label>
                                        <Form.Select
                    name="options"
                    value={formData.options}
                    onChange={handleChange}
                >
                                            <option>Open this select menu</option>
                                            <option value="1">One</option>
                                            <option value="2">Two</option>
                                            <option value="3">Three</option>
                                        </Form.Select>
                                    </div> */}
                                    <button type="button"     onClick={handleSubmit} class="btn btn-secondary btn-main">
                                        {/* <Link to={'/cart'} className='custom-link'> */}
                                            Add To Cart
                                        {/* </Link> */}
                                        <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <mask id="mask0_686_7789" maskUnits="userSpaceOnUse" x="0" y="0" width="25" height="24">
                                                <rect x="0.5" width="24" height="24" fill="#D9D9D9" />
                                            </mask>
                                            <g mask="url(#mask0_686_7789)">
                                                <path d="M13.4467 11.9995L8.84668 7.39945L9.90043 6.3457L15.5542 11.9995L9.90043 17.6532L8.84668 16.5995L13.4467 11.9995Z" fill="white" />
                                            </g>
                                        </svg></button>
                                    <div className='price-g'>
                                        <Link to="">
                                            Price Gurantee</Link>
                                    </div>

                                </div>
                                {/* <div className='cost'>
                                    <h4>Cost-to-Build Report</h4>
                                    <p>Still deciding? Calculate the cost to build this house in your area.</p>
                                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">

                                        <Form.Control type="text" placeholder="Zip Code" />
                                    </Form.Group>
                                    <button type="button" class="btn btn-secondary btn-main">Orders a cost to build <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <mask id="mask0_686_7789" maskUnits="userSpaceOnUse" x="0" y="0" width="25" height="24">
                                            <rect x="0.5" width="24" height="24" fill="#D9D9D9" />
                                        </mask>
                                        <g mask="url(#mask0_686_7789)">
                                            <path d="M13.4467 11.9995L8.84668 7.39945L9.90043 6.3457L15.5542 11.9995L9.90043 17.6532L8.84668 16.5995L13.4467 11.9995Z" fill="white" />
                                        </g>
                                    </svg></button>
                                </div> */}
                                {/* <div className='modify-plan'>
                                    <h4>Modify This Plan</h4>
                                    <p>Need to make changes? We will get you a free price quote within 1 to 3 business days.</p>
                                    <button type="button" class="btn btn-light w-100 ">Modify This Plan </button>
                                </div> */}
                                <div className='sq-brkdown'>
                                    <h5>Square Footage Breakdown</h5>
                                    <hr className='w-100 mt-4'></hr>
                                    <div className='total-list'>
                                        <p className='mb-0'>Total Heated Area</p>
                                        <div className='ft'>
                                            <span>{propertyDetails?.total_heated_area ||'-'} Sq. Ft,</span>
                                        </div>

                                    </div>
                                    <div className='total-list'>
                                        <p className='mb-0'>1st Floor</p>
                                        <div className='ft'>
                                            <span>{propertyDetails?.first_floor||"-"} Sq. Ft.</span>
                                        </div>

                                    </div>
                                    <div className='total-list'>
                                        <p className='mb-0'>Porch, Rear</p>
                                        <div className='ft'>
                                            <span>{propertyDetails?.porch_rear||"-"} Sq. Ft.</span>
                                        </div>

                                    </div>
                                    <div className='total-list'>
                                        <p className='mb-0'>Porch, Front</p>
                                        <div className='ft'>
                                            <span>{propertyDetails?.porch_front||"-"} Sq. Ft.</span>
                                        </div>

                                    </div>
                                </div>
                                <div className='sq-brkdown'>
                                    <h5>Beds/Baths</h5>
                                    <hr className='w-100 mt-4'></hr>
                                    <div className='total-list'>
                                        <p className='mb-0'>Bedrooms</p>
                                        <div className='ft'>
                                            <span>{propertyDetails?.beds|| 'N/A'}</span>
                                        </div>

                                    </div>
                                    <div className='total-list'>
                                        <p className='mb-0'>Full bathrooms</p>
                                        <div className='ft'>
                                            <span>{propertyDetails?.full_bathrooms|| 'N/A'}</span>
                                        </div>

                                    </div>
                                    {/* <div className='total-list'>
                                        <p className='mb-0'>Half bathrooms</p>
                                        <div className='ft'>
                                            <span>1</span>
                                        </div>

                                    </div> */}

                                </div>
                                <div className='sq-brkdown'>
                                    <h5>Foundation Type</h5>
                                    <hr className='w-100 mt-4'></hr>
                                    <div className='total-list'>
                                        <p className='mb-0'>Standard Foundations:</p>
                                        <div className='ft'>
                                            <span> {
                foundation_Select_data.find(
                    (type) => type.value == propertyDetails?.different_foundation
                )?.label || "N/A" // Fallback to "N/A" if no match
            }</span>
                                        </div>

                                    </div>
                                    <div className='total-list'>
                                        <p className='mb-0'>Optional Foundations:</p>
                                        <div className='ft'>
                                        <span> {
                foundation_Select_data.find(
                    (type) => type?.value == propertyDetails?.optional_foundation
                )?.label || "N/A" // Fallback to "N/A" if no match
            }</span>
                                        </div>

                                    </div>
                                    {/* <div className='total-list'>
                                        <p className='mb-0'>Need A Different Foundation?</p>
                                        <div className='ft'>
                                            <span>Request A Modification Quote</span>
                                           
                                        </div>
                                        
                                    </div> */}
                                    <button
        className="btn btn-secondary btn-main d-block mx-auto"
        style={{ 
          backgroundColor: '#ff6200', 
          color: '#ffffff', 
          cursor: isDisabled ? 'not-allowed' : 'pointer',
          opacity: isDisabled ? 0.6 : 1 
        }}
        onClick={handleClick}
        disabled={isDisabled}
      >
        Request A Different Foundation
      </button>
                                </div>
                                <div className='sq-brkdown'>
                                    <h5>Exterior Walls</h5>
                                    <hr className='w-100 mt-4'></hr>
                                    <div className='total-list'>
                                        <p className='mb-0'>Standard Type(s):</p>
                                        <div className='ft'>
                                            <span>{propertyDetails?.exterior_standard_type|| 'N/A'}</span>
                                        </div>

                                    </div>
                                    <div className='total-list'>
                                        <p className='mb-0'>Optional Type(s):</p>
                                        <div className='ft'>
                                        <span>{propertyDetails?.exterior_optional_type|| 'N/A'}</span>
                                        </div>

                                    </div>
                                  
                                     <button
        className="btn btn-secondary btn-main d-block mx-auto"
        style={{ 
          backgroundColor: '#ff6200', 
          color: '#ffffff', 
          cursor: isDisabled ? 'not-allowed' : 'pointer',
          opacity: isDisabled ? 0.6 : 1 
        }}
        onClick={handleClickforall}
        disabled={isDisabled}
      >
                                    Request A Different Wall Type 
                                    </button>
                                </div>
                                <div className='sq-brkdown'>
                                    <h5>Dimensions</h5>
                                    <hr className='w-100 mt-4'></hr>
                                    <div className='total-list'>
                                        <p className='mb-0'>Width:</p>
                                        <div className='ft'>
                                            <span>{propertyDetails?.footprint_width|| 'N/A'}</span>
                                        </div>

                                    </div>
                                    <div className='total-list'>
                                        <p className='mb-0'>Depth:</p>
                                        <div className='ft'>
                                            <span>{propertyDetails?.footprint_depth || 'N/A'}</span>
                                        </div>

                                    </div>
                                    <div className='total-list'>
                                        <p className='mb-0'>Max Ridge Height:</p>
                                        <div className='ft'>
                                            <span>{propertyDetails?.footprint_height || 'N/A'}</span>
                                        </div>

                                    </div>

                                </div>
                                <div className='sq-brkdown'>
                                    <h5>Garage</h5>
                                    <hr className='w-100 mt-4'></hr>
                                    <div className='total-list'>
    <p className='mb-0'>Type:</p>
    <div className='ft'>
        <span>
            {
                garage_type_Select_data.find(
                    (type) => type.value == propertyDetails?.garage_type
                )?.label || "N/A" // Fallback to "N/A" if no match
            }
        </span>
    </div>
</div>

                                    <div className='total-list'>
                                        <p className='mb-0'>Area:</p>
                                        <div className='ft'>
                                            <span>{propertyDetails?.garage_sq_ft||0} Sq. Ft.</span>
                                        </div>
                                        
                                    </div>
                                    <div className='total-list'>
                                        <p className='mb-0'>Count:</p>
                                        <div className='ft'>
                                            <span>{propertyDetails?.no_of_vehicles|| 'N/A'}</span>
                                        </div>

                                    </div>
                                    {/* <div className='total-list'>
                                        <p className='mb-0'>Entry Location:</p>
                                        <div className='ft'>
                                            <span>Side</span>
                                        </div>

                                    </div> */}
                                </div>
                                <div className='sq-brkdown'>
                                    <h5>Ceiling Heights</h5>
                                    <hr className='w-100 mt-4'></hr>
                                    <div className='total-list'>
                                        <p className='mb-0'>First Floor</p>
                                        <div className='ft'>
                                            <span>{propertyDetails?.ceiling_first_floor|| 'N/A'}'</span>
                                        </div>

                                    </div>

                                </div>
                                <div className='sq-brkdown'>
                                    <h5>Roof Details</h5>
                                    <hr className='w-100 mt-4'></hr>
                                    <div className='total-list'>
                                        <p className='mb-0'>Primary Pitch:</p>
                                        <div className='ft'>
                                            <span>{propertyDetails?.roof_primary_pitch|| 'N/A'}</span>
                                        </div>

                                    </div>
                                    <div className='total-list'>
                                        <p className='mb-0'>Secondary Pitch:</p>
                                        <div className='ft'>
                                        <span>{propertyDetails?.roof_secondary_pitch|| 'N/A'}</span>
                                        </div>

                                    </div>
                                    <div className='total-list'>
                                        <p className='mb-0'>Framing Type:</p>
                                        <div className='ft'>
                                        <span>{propertyDetails?.roof_framing_type || 'N/A'}</span>
                                        </div>

                                    </div>

                                </div>
                                <div className='sq-brkdown'>
                                    <h5>Architectural Style</h5>
                                    <hr className='w-100 mt-4'></hr>
                                    <div className='total-list'>
                                    {
              propertyDetails?.plan_style_id?.name|| "N/A"
              
            }


                                    </div>
                                 
                                

                                </div>
                            </div>
                        </div>
                        <section className="new-plans bg-white">
                            <div className="container">
                                <div className="top-heading">

                                {relatedProperties?.length > 0 && (
                                    <>   <h4>Related Plans</h4>
                                    </>
                                )}
                                </div>
                                {/* <CarouselProvider
                                      naturalSlideWidth={100}
            naturalSlideHeight={40}
            totalSlides={relatedProperties?.length} // Dynamically setting the total slides based on API data
            visibleSlides={3}
            infinite
                                >
                                    <Slider>
                                        <div index={0}>
                                        {
                  Array.isArray(relatedProperties) && relatedProperties?.length > 0 ?
                  relatedProperties?.map((res, index) => {
                      return <>
                                            <div className="row" key={res?._id}>
                                                <div className="col-lg-4 d-flex justify-content-end">
                                                    <div className="wrap">
                                                        <div className="img-h">
                                                        <img src={res?.main_images?.length > 0 ? res?.main_images[0] : imghouse1} style={{ height: '250px', width: '280px' }} />
                                                        <div className="sociali-btn">
                                                                <div className="youtube">
                                                                    <img src={youtubr} />
                                                                </div>
                                                                <div className="fav">
                                                                    <img src={fav} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="ex-tag">
                                                            <img src={exclusive} />
                                                        </div>
                                                        <div className="img-content">
                                                            <div className="h-details">
                                                                <div className="area">
                                                                    <strong>{res?.sq_ft}</strong>
                                                                    <p className="mb-0">Sq. Ft.</p>
                                                                </div>
                                                                <hr></hr>
                                                                <div className="area">
                                                                    <strong>{res?.beds}</strong>
                                                                    <p className="mb-0">Bed</p>
                                                                </div>
                                                                <hr></hr>
                                                                <div className="area">
                                                                    <strong>{res?.baths}</strong>
                                                                    <p className="mb-0">Bath</p>
                                                                </div>
                                                                <hr></hr>
                                                                <div className="area">
                                                                    <strong>{res?.story}</strong>
                                                                    <p className="mb-0">Story</p>
                                                                </div>
                                                                <hr></hr>
                                                                <div className="area">
                                                                    <strong>{res?.cars}</strong>
                                                                    <p className="mb-0">Cars</p>
                                                                </div>
                                                            </div>
                                                            <hr></hr>
                                                            <div className="d-flex justify-content-between">
                                                                <div className="pln-no">
                                                                    <span>Plan Number</span>
                                                                    <h2>#{res.plan_number}</h2>
                                                                </div>
                                                                <div className="pln-no">
                                                                    <span>Starts From</span>
                                                                    <h2 className="price">${res?.price}</h2>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                               
                                            </div>
                                            </>
                                        })
                    : <p className="text-center">No Data Found</p>
                }
                                        </div>
                                      
                                    </Slider>
                                    <ButtonBack>
                                        <img src={leftarrow} />
                                    </ButtonBack>
                                    <ButtonNext>
                                        <img src={rightarrow} />
                                    </ButtonNext>
                                </CarouselProvider> */}
                                <CarouselProvider
            naturalSlideWidth={100}
            naturalSlideHeight={40}
            totalSlides={relatedProperties?.length} // Dynamically setting the total slides based on API data
            visibleSlides={3}
            infinite
          >
            <Slider>
          
              <div className='plans-farms'>
                {
                  Array.isArray(relatedProperties) && relatedProperties?.length > 0 ?
                    relatedProperties?.map((res, index) => {
                      return <>
                        <div className="wrap" key={res?._id}>
                          <Link to={`/property/${res?._id}`} className="custom-link">
                            <div className="img-h">
                              <img src={res?.main_images?.length > 0 ? res?.main_images[0] : imghouse1} style={{ height: '250px', width: '280px' }} />
                              <div className="sociali-btn">
                                <div className="youtube">
                                  <img src={youtubr} />
                                </div>
                                <div className="fav">
                                  <img src={fav} />
                                </div>
                              </div>
                            </div>
                            <div className="ex-tag">
                              <img src={exclusive} />
                            </div>
                            <div className="img-content">
                              <div className="h-details">
                                <div className="area">
                                  <strong>{res?.sq_ft || '0'}</strong>
                                  <p className="mb-0">Sq. Ft.</p>
                                </div>
                                <hr></hr>
                                <div className="area">
                                  <strong>{res?.beds || '0'}</strong>
                                  <p className="mb-0">Bed</p>
                                </div>
                                <hr></hr>
                                <div className="area">
                                  <strong>{res?.baths || '0'}</strong>
                                  <p className="mb-0">Bath</p>
                                </div>
                                <hr></hr>
                                <div className="area">
                                  <strong>{res?.story || '0'}</strong>
                                  <p className="mb-0">Story</p>
                                </div>
                                <hr></hr>
                                <div className="area">
                                  <strong>{res?.cars || '0'}</strong>
                                  <p className="mb-0">Cars</p>
                                </div>
                              </div>
                              <hr></hr>
                              <div className="d-flex justify-content-between">
                                <div className="pln-no">
                                  <span>Plan Number</span>
                                  <h2>{res?.plan_number ? `#${res?.plan_number}` : 'N/A'}</h2>
                                </div>
                                <div className="pln-no">
                                  <span>Starts From</span>
                                  <h2 className="price">${res?.price || '0'}</h2>
                                </div>
                              </div>
                            </div>
                          </Link>
                        </div>
                      </>
                    })
                    : <p className="text-center"></p>
                }
              </div>
              
            </Slider>
            {relatedProperties?.length > 0 && (
  <>
    <ButtonBack>
      <img src={leftarrow} alt="Back" />
    </ButtonBack>
    <ButtonNext>
      <img src={rightarrow} alt="Next" />
    </ButtonNext>
  </>
)}

          </CarouselProvider>
                            </div>
                        </section>
                    </div>
                </section>
            ) : (
                <p>No property found.</p>
            )}

            </Layout>
        </>
    )
}

export default PropertyDetail
